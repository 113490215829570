@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');


.login-container {
  background: #FFFFFF;
  box-shadow: -12px 11px 13px rgba(0, 0, 0, 0.15), inset -10px -7px 16px rgba(0, 0, 0, 0.15);
  border-radius: 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: inter, sans-serif;
  padding: 20px;
  max-width: 350px;

  .img-container {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 0 8px rgba(0, 0, 0, 0.15), inset -3px -2px 4px rgba(0, 0, 0, 0.25);
    width: 50%;
    max-width: 140px;
  }

  .tg-login-text {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    color: #56A9EB;
  }

  .user-name-text {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    color: #8239A1;
  }

  .user-username-text {
    font-family: Inter, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;

    color: #8239A1;
  }

  .login-container-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #A269BA;
  }

  .go-app-button {
    background-color: #A36BC8;
    color: white;
    border-radius: 27px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 14px;
    font-family: Inter, sans-serif;
    font-weight: 700;

    &:active {
      color: white;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
}
