$primary: var(--tg-theme-button-color);
$spliter-color: var(--tg-theme-text-color, grey);
$button-color: var(--tg-theme-button-color, black);
$text-color: var(--tg-theme-text-color, black);
$hint-color: var(--tg-theme-hint-color, grey);

:root {
    --tg-theme-secondary-button-color: #000;
}

body {
    overflow-y: scroll;
    background-color: var(--body-bg, var(--tg-theme-secondary-bg-color, #fff));
    font-family: Roboto, Helvetica, Arial, sans-serif;
}

.flex-row{
    display: flex;
    flex-wrap: wrap;

    .col {
        min-width: 50%;
    }
}

.tg-bg {
    background-color: var(--tg-theme-bg-color, #fff);
    color: var(--tg-theme-text-color, #000);
}

.tg-card {
    background-color: var(--tg-theme-bg-color, #fff);
    color: var(--tg-theme-text-color, #000);

    > .catalog-card-media {
        img, video {
            max-width: 100%;
        }
    }

    .card-footer {
        border-top: .0001px ridge $spliter-color;
    }
}

.tg-secondary-bg {
    background-color: var(--tg-theme-secondary-bg-color, #fff);
    color: var(--tg-theme-text-color, #000);
}

.tg-link, .tg-link:hover, .tg-link:active, .tg-link:focus {
    color: var(--tg-theme-link-color, #000)!important;
    text-decoration: underline;
}

.no-underline {
    text-decoration: none!important;
}

.tg-button {
    background-color: var(--tg-theme-button-color, #000)!important;
    color: var(--tg-theme-button-text-color, #fff)!important;

    &:hover, &:active {
        background-color: var(--tg-theme-secondary-button-color)!important;
    }
}

.tg-button-color {
    color: var(--tg-theme-button-color, #000);
}

.tg-button-bg {
    background-color: var(--tg-theme-button-color, #000);
    color: var(--tg-theme-button-text-color, #fff)
}

.tg-link-color {
    color: var(--tg-theme-link-color, #000);
}

.tg-hint-color {
    color: var(--tg-theme-hint-color, grey)!important;
}

.tg-text {
    color: var(--tg-theme-text-color, #000)!important;
}

.tg-breadcrumb {
    > .breadcrumb-item::before {
        color: var(--tg-theme-hint-color, #000)!important;
    }
}

.tg-radio {
    background-color: var(--tg-theme-bg-color, #fff)!important;
    accent-color: var(--tg-theme-button-color, #000)!important;
    border-color: var(--tg-theme-secondary-button-color)!important;

    &:checked:after {
        background-color: var(--tg-theme-secondary-button-color)!important;
    }

    &:not(:checked):after {
        background-color: var(--tg-theme-bg-color, #fff)!important;
    }

}

.tg-modal {
    .modal-dialog {
        background-color: var(--tg-theme-bg-color, #fff)!important;

        .modal-header {
            background-color: var(--tg-theme-bg-color, #fff)!important;
            color: var(--tg-theme-text-color, #000)!important;
            border-bottom: 1px solid var(--tg-theme-hint-color, grey);
        }

        .modal-body {
            background-color: var(--tg-theme-bg-color, #fff)!important;
            color: var(--tg-theme-text-color, #000)!important;

            input {
                background-color: var(--tg-theme-bg-color, #fff)!important;
            }
        }

        .modal-footer {
            background-color: var(--tg-theme-bg-color, #fff)!important;
            color: var(--tg-theme-text-color, #000)!important;
            border-top: 1px solid var(--tg-theme-hint-color, grey);
        }
    }
}

.tg-table-editor-wrapper {

    .tg-table-editor {
        background-color: var(--tg-theme-bg-color, white);
        color: var(--tg-theme-text-color, red);

        table {

            thead {
                tr {
                    border-bottom: 1px solid var(--tg-theme-hint-color, grey)!important;
                }
            }

            tbody  {
                tr:last-child {
                    border-bottom: 1px solid var(--tg-theme-hint-color, grey)!important;
                }
            }

            border-bottom: 1px solid var(--tg-theme-hint-color, grey)!important;
            color: var(--tg-theme-text-color, red);

            td {
                color: var(--tg-theme-text-color, red);
            }

            button {
                color: var(--tg-theme-text-color, red)!important;
            }
        }
        .select-arrow {
            color: var(--tg-theme-text-color, red);
        }

        .table-editor__pagination {
            border-top: none;
            margin: 0;
            justify-content: end;

            .table-editor__select-wrapper {
                text-align: center;
                width: 50%;
                padding: 0;

                .table-editor__select-text{
                    padding: 0;
                    margin: 0 .5rem 0 0;
                }
            }

            .table-editor__pagination-nav {
                text-align: center;
                margin-left: .5rem;
            }

            .table-editor__pagination-buttons {
                text-align: end;
                margin-left: .5rem;
            }
        }

    }

    > div:first-child {
        .btn {
            background-color: var(--tg-theme-button-color, #000)!important;
            color: var(--tg-theme-button-text-color, #fff)!important;

            &:hover, &:active {
                background-color: var(--tg-theme-secondary-button-color)!important;
            }
        }
    }

    .form-label {
        color: var(--tg-theme-text-color, red)!important;
    }

    .form-control {
        color: var(--tg-theme-text-color, red)!important;
    }

    .form-control:disabled {
        background-color: var(--tg-theme-secondary-bg-color, grey);
    }
}

.tg-switch {
    background-color: var(--tg-theme-hint-color, grey)!important;

    &::after {
        background-color: white!important;
    }

    &:checked {
        background-color: var(--tg-theme-button-color, #000)!important;
        outline: none;
        &::after {
            background-color: var(--tg-theme-button-color, #000)!important;
        }
    }
}

.rfs-1 {
    font-size: 1vw
}

.rfs-2 {
    font-size: 2vw
}

.rfs-3 {
    font-size: 3vw
}

.rfs-3-5 {
    font-size: 3.5vw
}

.rfs-4 {
    font-size: 4vw
}

.rfs-5 {
    font-size: 5vw
}

.dropdown-menu  a.dropdown-item {
    background-color: var(--tg-theme-bg-color, #fff) !important;
    color: var(--tg-theme-text-color, #000) !important
}

.dropdown-menu  a.dropdown-item:hover {
    background-color: var(--tg-theme-button-color, #000) !important;
    color: var(--tg-theme-button-text-color, #fff) !important
}

.popconfirm {
    background-color: var(--tg-theme-bg-color, #fff)!important;
    color: var(--tg-theme-text-color, #000)!important;

    .popconfirm-message {
        background-color: var(--tg-theme-bg-color, #fff)!important;
        color: var(--tg-theme-text-color, #000)!important;
    }

    .popconfirm-buttons-container {
        display: flex;
        justify-content: center;

        .btn:first-child {
            margin-right: 0.75rem;
        }

        .btn {
            margin-left: 0!important;
        }
    }
}

.form-switch .form-check-input:before {
    display: none;
}


.input-no-outline, .input-no-outline:focus, .input-no-outline:active {
    outline: none!important;
    border: none!important;
    border-color: white;
}

.tg-chip {
    justify-content: space-between;
    border-color: var(--tg-theme-button-color, #000)
}

input[type=range]::-webkit-slider-thumb {
    background-color: var(--tg-theme-button-color, var(--mdb-primary))!important;
}


.thumb::after {
    background-color: var(--tg-theme-button-color, var(--mdb-primary))!important;
}

.word-wrap-pre-line {
    white-space: pre-wrap;
}

.tg-button-link, .tg-button-link:hover, .tg-button-link:focus, .tg-button-link:active {
    border: none!important;
    background: none!important;
    color: var(--tg-theme-link-color, black)!important
}

.pop-confirm-btn, .pop-confirm-btn:hover, .pop-confirm-btn:focus, .pop-confirm-btn:active {
    box-shadow: none!important;
}


.tg-select {
    .select-input,  {
        color: $text-color !important;

        &.focused {
            color: $text-color !important;
        }
    }
}


.select-option {
    background-color: var(--tg-theme-bg-color, #fff)!important;
    color: $text-color!important;

    &.selected, &.active {
        background-color: var(--tg-theme-secondary-bg-color, lightgrey)!important;
    }
}


.select-options-wrapper {
    background-color: var(--tg-theme-bg-color, #fff)!important;
}


.select-custom-content {
    background-color: var(--tg-theme-bg-color, #fff)!important;
}

.btn.btn-primary.checked {
    background-color: #093d94!important
}


.flex-1 {
    flex: 1
}
