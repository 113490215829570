@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');


.use-certificate-card {
  background: #FFFFFF;
  box-shadow: -12px 11px 13px rgba(0, 0, 0, 0.15), inset -10px -7px 16px rgba(0, 0, 0, 0.15);
  border-radius: 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: inter, sans-serif;
  padding: 20px;
  max-width: 400px;

  .header-text {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #8239A1;
  }

  .code-text {
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    color: #8239A1;
  }

  .scan-code-in-app-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #8239A1;
  }

  .error-text {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    color: #8239A1;
  }

  .app-buttons-container {
  }

  .button {
    color: white;
    border-radius: 27px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 14px;
    font-family: Inter, sans-serif;
    font-weight: 700;
    padding-left: .8rem!important;
    padding-right: .8rem!important;

    &.go-app-button {
      background-color: #A36BC8;
    }

    &:active {
      color: white;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
}
